<template>
  <div>
    <h1 class="display-l">Iconography</h1>

    <Tabs>
      <Tab title="Styleguide" id="style"><IconsStyleguide /></Tab>
      <Tab title="Library" id="library"><IconsLibrary /></Tab>
    </Tabs>

  </div>
</template>

<script>
import Tabs from "../common/Tabs.vue"
import Tab from "../common/Tab.vue"
import IconsStyleguide from "./iconography/IconsStyleguide.vue"
import IconsLibrary from "./iconography/IconsLibrary.vue"

export default {
  name: "Iconography",
  components: {
    Tabs,
    Tab,
    IconsStyleguide,
    IconsLibrary
  },
};
</script>