<template>
    <div class="icon-modal">
      <h1 class="heading left">Download Icon</h1>
      <div class="right close" @click="closeModal()">x</div>

      <!-- selection -->
      <div class="flex-container">
        <div class="col w-50" v-if="!selectedIcon.pathLong.includes('gradient')">
          <h2 class="subheading">Select color: </h2>
          <select name="colors" @change="changeColor($event)">
            <option value="#1C2F5E" selected>Royal Blue</option>
            <option value="#606D8E">Gray</option>
            <option value="#426FE1">Dodger blue</option>
            <option value="#FFFFFF">White</option>
            <option value="#77AEFF">Cornflower</option>
            <option value="#3F817F">Dark mint</option>
          </select>
        </div>

        <div class="col w-50">
          <h2 class="subheading">Pick scale:</h2>
          <select name="colors" @change="changeScale($event)">
            <option value="2" selected>2x</option>
            <option value="3">3x</option>
            <option value="4">4x</option>
            <option value="10">10x</option>
          </select>
        </div>
      </div>

      <div class="img-bg" :class="{'reversed': svgColor === '#FFFFFF'}">
        <div v-html="svg ? svg.innerHTML : null"></div>
      </div>

      <div class="flex-container">
        <button @click="downloadSvg()">Download SVG</button>
        <button @click="convertToPng()">Download PNG</button>
      </div>
    </div>
</template>

<script>
import {saveSvgAsPng} from 'save-svg-as-png';

export default {
  name: "IconModal",
  props: {
    selectedIcon: Object,
  },
  data() {
    return {
      svg: null,
      scale: 2,
      svgColor: '#1C2F5E',
      id: 0,
      // gradient: '<linearGradient x1="50%" y1="-23.5440341%" x2="50%" y2="65.5229048%" id="linearGradient"><stop stop-color="#275EE9" offset="0%"></stop><stop stop-color="#C83667" offset="100%"></stop></linearGradient>'
    }
  },
  mounted() {
    this.getSvg();

    this.id = this.convertId(this.selectedIcon.pathLong);
  },
  methods: {
    getSvg() {
      const parser = new DOMParser();
      fetch(this.selectedIcon.pathLong)
      // Get SVG response as text
      .then(response => response.text())
      .then(text => {
        // Turn the raw text into a document with the svg element in it.
        const parsed = parser.parseFromString(text, 'text/html');
        // Select the <svg> element from that document.
        this.svg = parsed.querySelector('svg');
        
        let svgString = this.svg.outerHTML;
        // if (this.svgColor === 'gradient') {
        //   svgString = this.svg.outerHTML.replaceAll("<defs>", "<defs>" + this.gradient);
        //   svgString = svgString.replaceAll("#1C2F5E", "url(#linearGradient)"); // change color
        // } else {
          svgString = this.svg.outerHTML.replaceAll("#1C2F5E", this.svgColor); // change color
        // }

        this.svg.innerHTML = svgString;
      })
    },
    convertId(path){
      let id = path.substring(1);
      id = id.split('.')[0].toLowerCase().replace(/\s/g, '');
      return id;
    },
    convertToPng() {
      saveSvgAsPng(this.svg, this.id +".png", {scale: this.scale});
    },
    downloadSvg() {
      const blob = new Blob([this.svg.innerHTML.toString()]);
      const element = document.createElement("a");
      element.download = this.id + '.svg';
      element.href = window.URL.createObjectURL(blob);
      element.click();
      element.remove();
    },
    closeModal() {
      this.$emit("toggleModal", null);
    },
    changeColor(event) {
      this.svgColor = event.target.value;
      this.getSvg();
    },
    changeScale(event) {
      this.scale = parseInt(event.target.value);
    }
  },
};
</script>