<template>
    <div class="with-anchors">
        <AnchorMenu :anchors="anchors" />

        <p>Icons and infographics can add visual interest and clarity of communication to your applications. <br/>
        There is an extensive set of on-brand icons that has been developed for use in Intelematics communications. </p>

        <!--Value -->
        <section>
            <h2 id="value" class="display-m">Value icons</h2>
            <p>
                There are three icons allocated to each of the our values, as shown below. It is important that these icons are not used to represent other themes to avoid confusion.
            </p>
            <p><img src="../../../assets/img/iconography1.png" alt="" role="presentation" width="1000" /></p>

            <DownloadCTA title="Download value icons" 
                description="Files available in .SVG and .PNG formats" 
                buttonTitle="Download .zip"
                link="/zip/Intelematics-value-icons.zip" />
        </section>

        <!-- Hero -->
        <section>
            <h2 id="hero" class="display-m">Hero icons</h2>
            <p>
                Our hero icon style uses a combination of navy and the gradient to create symbols that are more visually dynamic and have a greater sense of motion. They are to be used sparingly to ensure maximum standout and to avoid becoming visually cluttered.
            </p>
            <p><img src="../../../assets/img/iconography2.png" alt="" role="presentation" width="1000" /></p>

        <div class="alert">Please note, Hero icons should be created by designers. If you are need assistance please contact the design team: <a href="mailto:valeria.gottau@intelematics.com">Valeria Gottau valeria.gottau@intelematics.com </a></div>
        </section>

         <!-- Functional -->
        <section>
            <h2 id="functional" class="display-m">Functional icons</h2>
            <p>
               When selecting icons for general use across our products UI we have a robust set available that have been created to be fit for purpose.
            </p><br/>

            <p class="section-heading">Principles</p>
            <p>
                <span style="color:#3F817F">Filled over outlined </span><br/>
                We recommend to use filled icons as main style in your product UI. 
            </p>
            <p>
                <span style="color:#3F817F">Simple over detailed</span><br/>
                Detailed icons increase cognitive load. Focus on simplicity to help users understand the concept the icon represents and recognise icons on smaller screens.
            </p>
            <p>
                <span style="color:#3F817F">Pair icons with text</span><br/>
                As a general rule, icons should be used in combination with meaningful text to support users progressing through the screen in an accessible manner. 
            </p><br/>

            <p class="section-heading">Colour</p>
            <p>Our functional icons are available to use in 6 colour variations as follows: </p>
            <p><img src="../../../assets/img/iconography3.png" alt="" role="presentation" /></p><br/>
            <p>
                <span style="color:#3F817F">Navy</span><br/>
                Formal, basic and easy to read; it ensures enough contrast when used on light backgrounds. 
            </p>
            <p>
                <span style="color:#3F817F">Grey</span><br/>
                Subtle and neutral; can be used next to a label, for example in a navigation menu. 
            </p>
            <p>
                <span style="color:#3F817F">White</span><br/>
                Commonly used on dark backgrounds. 
            </p>
            <p>
                <span style="color:#3F817F">Dodger blue and Cornflower </span><br/>
                Used for icon buttons. Use Dodger blue for Light Theme and Light Cornflower for Dark Themes. 
            </p>
            <p>
                <span style="color:#3F817F">Dark mint</span><br/>
                Used in icon boxes in our website.
            </p>
            <p>For components containing confirmations, alerts, information, and acknowledgments, follow the guidelines associated with the component and use case.
                Using icons with a text label and color will enhance the usability and accessibility for all users.
            </p><br/>

            <p class="section-heading">Example</p>
            <p><img src="../../../assets/img/iconography4.png" alt="" role="presentation" /></p>

        </section>

    </div>
</template>

<script>
import { iconographyAnchors } from "../../../assets/data/anchors";
import AnchorMenu from "../../common/AnchorMenu.vue";
import DownloadCTA from "../../common/DownloadCTA"
import "vue-code-highlight/themes/prism.css";

export default {
  name: "IconsStyleguide",
  
  components: {
    AnchorMenu,
    DownloadCTA,
  },
  data() {
    return {
      anchors: iconographyAnchors,
    };
  },
};
</script>