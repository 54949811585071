<template>
  <div>
    Click on the icon to download it as SVG or PNG. If you are developer, you can download our <a href="https://github.com/intelematics/designlab-resources/blob/main/svg-sprites" target="#">SVG sprites here.</a>

    <section v-for="item in iconsList" :key="item.title">
      <h2 class="display-m">{{item.title}}</h2>
      <div class="flex-container wrap icons-wrapper">
        <div class="col" v-for="icon in item.svgs" :key="icon.id">
          <Icon :icon="icon" />
        </div>
      </div>
    </section>

    <!-- <section class="form-container">
      <h2>Missing an icon?</h2>
      <p>Let us know which one and we'll make it for you.</p>
      <center><iframe title="icons" src="https://go.intelematics.com/l/715203/2021-09-15/2fzhh8" width="100%" height="230" type="text/html" frameborder="0" allowTransparency="true" ></iframe></center>
    </section> -->

  </div>
</template>

<script>
import Icon from "./Icon.vue"

export default {
  name: "IconsLibrary",
  components : {
    Icon
  },
  data() {
    return {
      concepts: [],
      industries: [],
      interfaces: [],
      technology: [],
      mobility: [],
      iconsList: [],
      scale: 2,
    };
  },
  mounted() {
    this.importAll(this.concepts, require.context("@/assets/img/icons/svg/concepts", true, /\.svg$/));
    this.importAll(this.industries, require.context("@/assets/img/icons/svg/industries", true, /\.svg$/));
    this.importAll(this.interfaces, require.context("@/assets/img/icons/svg/interface", true, /\.svg$/));
    this.importAll(this.mobility, require.context("@/assets/img/icons/svg/mobility", true, /\.svg$/));
    this.importAll(this.technology, require.context("@/assets/img/icons/svg/technology", true, /\.svg$/));

    this.iconsList.push(
      {title: "Concepts", svgs: this.concepts},
      {title: "Industries", svgs: this.industries},
      {title: "Interface", svgs: this.interfaces},
      {title: "Mobility", svgs: this.mobility},
      {title: "Technology", svgs: this.technology},
    )
  },

  methods: {
    importAll(category, r) {
      r.keys().forEach((key) => {
        category.push({ pathLong: r(key), pathShort: key })
      });
    },
  },
};
</script>