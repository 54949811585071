<template>
  <div>
    <div class="icon-container" :class="{'reversed': icon.pathLong.includes('reversed') }">
      <img :src="icon.pathLong"
            :id="convertId(icon.pathShort)" 
            @click="toggleModal(icon)"/>
    </div>
    <p class="icon-name">{{getName(icon.pathShort)}}</p>
    
    <IconModal :selectedIcon="selectedIcon" 
                :scale="scale" 
                v-if="selectedIcon" 
                @toggleModal="toggleModal()"/>
  </div>
</template>

<script>
import IconModal from './IconModal.vue'

export default {
  name: "Icon",
  components: {
    IconModal
  },
  data() {
    return {
      selectedIcon: null,
    }
  },
  props: {
    icon: Object,
    svgColor: String,
    scale: Number,
  },
  methods: {
    getName(path) {
      let name = path.substring(2);
      name = name.split('.')[0];

      return name;
    },
    convertId(path){
      let id = this.getName(path)
      id = id.toLowerCase().replace(/\s/g, '');
      return id;
    },
    toggleModal(icon) {
      this.selectedIcon = icon;
    }
  }
};
</script>